<template>
  <div class="body fixed mobile">
    <section class="header">
        <div class="flexH width">
          <a
            class="icon iconButton third"
            @click.prevent="$router.push('/profile')"
          >
            <font-awesome-icon
              icon="fa-solid fa-chevron-left"
              size="lg"
            ></font-awesome-icon>
          </a>
        </div>
        <span class="fL nowrap"> {{ $t("message.DDPAY") }}</span>
        <div class="flexH width right"></div>
      </section>
    <div id="main" class="mainProfile ddpayGet">
      <Spinner :spin="isLoading || isLoadingActivity" />

      <div class="card">
        <span v-if="!ddpayAccount.partnerMemberId" class="bg-dark">
          <p>{{ $t("message.unbound") }}</p>
        </span>
      </div>

      <div class="Note" v-html="settings.description" />

      <div v-if="!isLoading && ddpayAccount.partnerMemberId" class="remove">
        <span>若要解除綁定點點付，請按此</span>
        <a @click="onUnbindClick">《解除綁定》</a>
        <span>。</span><br /><br />
        <div v-if="ddwalletUrl" class="flexH">
          <a class="button" :href="ddwalletUrl">
            <span class="fM">前往點點付錢包</span>
          </a>
        </div>
      </div>
      <div v-else-if="!isLoading" class="flexH">
        <a class="button" @click.prevent="onBindClick">
          <span class="fM">前往綁訂</span>
        </a>
      </div>
      <section id="popNotice" class="popup">
        <div class="popBody">
          <a class="close"><font-awesome-icon icon="fa-solid fa-times" /></a>
          <section class="popHeader">
          </section>
          <section class="popMain">
            <span class="fL">{{ errorMessage }}</span>
          </section>
          <section class="popFooter">
            <a class="button submit rounded"><span class="fM">確認</span></a>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Spinner from "@/components/Spinner";
// import utils from '@/services/utils';

export default {
  name: "BindDdpay",
  components: {
    Spinner,
  },
  data: () => {
    return {
      isLoading: false,
      isLoadingActivity: false,
      isProcessing: false,
      errorMessage: "",
      pmCode: "ddpay",
      apiHost: process.env.VUE_APP_API_HOST,
    };
  },
  head() {
    return {
      title: "點點付",
    };
  },
  computed: {
    ...mapState(["user", "userInfo"]),
    ...mapGetters({
      ddpayAccount: "ddpay/getLinkedAccount",
      settings: "ddpay/getSettings",
    }),
    ddwalletUrl() {
      return process?.env?.VUE_APP_DDWALLET_URL;
    },
  },
  created() {
    this.unwatchUserProp = this.$watch("user", (newVal) => {
      if (newVal && newVal.userId) {
        this.unwatchUserProp();
        this.getDDPayAccount();
        this.getSettings();
      }
    });
  },
  mounted() {
    // this.init();
    if (this.user && this.user.userId) {
      this.getDDPayAccount();
      this.getSettings();
    }
  },
  methods: {
    popNotice() {
      var popNotice = document.getElementById('popNotice');
      var close = popNotice.getElementsByClassName('close')[0];
      var submit = popNotice.getElementsByClassName('submit')[0];
      close.onclick = function () {
        popNotice.style.display = 'none';
      };
      submit.onclick = function () {
        popNotice.style.display = 'none';
      };
      window.onclick = function (event) {
        if (event.target == popNotice) {
          popNotice.style.display = 'none';
        }
      };
      popNotice.style.display = 'flex';
    },
    getDDPayAccount() {
      this.isLoading = true;
      const params = {
        lineUserId: this.user.userId,
        merchantId: this.user.merchantId,
        pmCode: this.pmCode,
      };

      this.$store.dispatch("ddpay/getLinkedAccount", params).finally(() => {
        this.isLoading = false;
      });
    },
    getSettings() {
      this.isLoadingActivity = true;
      const params = {
        lineUserId: this.user.userId,
        merchantId: this.user.merchantId,
        pmCode: this.pmCode,
      };
      this.$store
        .dispatch("ddpay/getSettings", params)
        .catch(() => {
          const errorMessage = this.$i18n.t(
            "message.failedToGetActivityContent"
          ); // '無法取得活動內容，請稍後重試。';
          this.onAlert(errorMessage);
        })
        .finally(() => {
          this.isLoadingActivity = false;
        });
    },
    onBindClick() {
      console.log('onBindClick');
      this.isProcessing = true;
      const params = {
        lineUserId: this.user.userId,
        merchantId: this.user.merchantId,
        vendorCode: this.pmCode,
        continueUrl: window.location.href,
      };
    
      this.$store
        .dispatch("ddpay/getLinkedUri", params)
        .then((res) => {
          console.log('-----get link uri----', res);
          const linkUri = res?.data?.linkUri;
          if (!linkUri) {
            throw new Error('No link uri');
          }
          window.location.href = linkUri;
        })
        .catch(() => {
          const errorMessage = this.$i18n.t("message.failedToGetBindingUrl"); // '無法取得綁定連結，請稍後重試。';
          this.onAlert(errorMessage);
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    onUnbindClick() {
      if (this.ddpayAccount && !this.ddpayAccount.partnerMemberId) {
        const errorMessage = this.$i18n.t("message.noBoundAccount", {
          account: this.$i18n.t("message.DDPAY"),
        }); // '尚未綁定任何一卡通帳號。';
        this.onAlert(errorMessage);
        return;
      }

      this.isProcessing = true;
      const params = {
        lineUserId: this.user.userId,
        merchantId: this.user.merchantId,
        pmCode: this.pmCode,
      };
      this.$store
        .dispatch("ddpay/unlink", params)
        .then(() => {
          this.onAlert(this.$i18n.t("message.unbindSuccess"));
          // this.getLinkedAccount();
        })
        .catch(() => {
          const errorMessage = this.$i18n.t("message.failedToUnbindAccount"); // '解除綁定失敗，請稍後重試。';
          this.onAlert(errorMessage);
          // this.onAlert(this.$i18n.t('message.unbindSuccess'));
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    onAlert(msg) {
      this.errorMessage = msg;
      this.popNotice();
    },
  },
};
</script>

<style scoped src="@/assets/css/ddpay.css"></style>
